<script setup lang="ts"></script>

<template>
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_659_5697)">
            <path
                d="M119 70C119 79.6913 116.126 89.1649 110.742 97.2229C105.358 105.281 97.7051 111.561 88.7515 115.27C79.7979 118.979 69.9456 119.949 60.4406 118.058C50.9355 116.168 42.2045 111.501 35.3518 104.648C28.499 97.7955 23.8322 89.0645 21.9415 79.5594C20.0508 70.0544 21.0212 60.2021 24.7299 51.2485C28.4386 42.2949 34.7191 34.6422 42.7771 29.258C50.8351 23.8738 60.3087 21 70 21"
                stroke="#50A0EB"
                stroke-width="2"
                stroke-linecap="round"
            />
        </g>
        <g filter="url(#filter1_d_659_5697)">
            <path
                d="M74.1163 21.1732C85.6038 22.1417 96.3819 27.1293 104.555 35.2592C112.729 43.389 117.774 54.1403 118.804 65.6223"
                stroke="#50A0EB"
                stroke-width="2"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_659_5697"
                x="0.200001"
                y="0.200001"
                width="139.6"
                height="139.6"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="9.9" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.313726 0 0 0 0 0.627451 0 0 0 0 0.921569 0 0 0 0.66 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_659_5697"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_659_5697"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d_659_5697"
                x="53.3162"
                y="0.373341"
                width="86.288"
                height="86.0492"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="9.9" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.313726 0 0 0 0 0.627451 0 0 0 0 0.921569 0 0 0 0.66 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_659_5697"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_659_5697"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>
